import { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Loading from 'pages/loading/Loading';
import NotiFireBase from 'components/common/Notification/NotiFireBase';
import { isSupported } from "firebase/messaging";
import { ConfigProvider } from 'antd';
import viVN from 'antd/lib/locale/vi_VN';


import { HOME_PAGE, ORDER_PAGE } from 'helper/routes';
import dayjs from 'dayjs';

const Home = lazy(() => import('pages/home/Home'));
const Order = lazy(() => import('pages/order/Order'));
const Layout = lazy(() => import('components/layout/Layout'));

const hasFirebaseMessagingSupport = await isSupported();
var customParseFormat = require('dayjs/plugin/customParseFormat')

dayjs.extend(customParseFormat)

function App() {

  return (
    <ConfigProvider locale={viVN}>
      <Suspense fallback={<Loading />}>
        {/* {
          hasFirebaseMessagingSupport && <NotiFireBase></NotiFireBase>
        } */}

        <BrowserRouter basename='/giahaninternet'>
          {/* <BrowserRouter> */}
          <Routes>
            <Route path={HOME_PAGE} element={<Layout />}>
              <Route index element={<Home />} />
              <Route path={ORDER_PAGE} element={<Order />} />

              {/* <Route path="*" element={<NotFound />} /> */}
              <Route path="*" element={<Home />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Suspense>
    </ConfigProvider>
  );
}

export default App;
